//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import InventarioServices from "../../Services/InventarioServices";

  const inventarioServices = new InventarioServices();
//Definición de constantes
const CONTROL_INVENTARIO_BASICO = 100;
const CONTROL_INVENTARIO_NUMERO_SERIE = 200;
const CONTROL_INVENTARIO_LOTES_CADUCIDADES = 300;
const CONTROL_INVENTARIO_LOTES = 400;
export default {
  name: "salidaLotesSeries",
  components: {
    BuscarVentasModal: () => import("./modales/buscarVentasModal.vue"),
  },
  data() {
    return {
      ventaId: this.$route.params.id,
      ventaObj: "",
      productos: "",
      buscarVentasModal: false,
      productoObj:"",
      modalSalidaProducto:false,
      filtrosLotes:{
        busqueda:""
      },
      columnasLotes:[
        {
          name: "index",
          label: "##",
          align: "center",
          headerClasses: "w4p",
        },
        {
          name: "lote",
          label: "Lote / Serie",
          field:"loteSerie",
          align: "left",
          headerClasses: "w89p",
        },
        {
          name: "cantidad",
          label: "Cantidad",
          field:"cantidadDisponible",
          align: "center",
          headerClasses: "w5p",
        },
      ],
      tienda:"",
      lstLotesSeries:[],
      productosLotes:[],
      loteSerieSeleccionado: [],
      modalConfigurarCantidades:false,
      loteSerieObj:"",
      cantidadSalidaLotesSeries:"",
      modalConfirmacion:false,
      indexSeleccionado: "",
    };
  },
  computed: {
    totalFinalizar() {
      let productos = _.filter(
        this.productos,
        (cp) => Number(cp.controlInventario) > 100
      );
      return _.sumBy(productos, (p) => Number(p.cantidad));
    },
    porFinalizar() {
      let productos = _.filter(this.productos, { entregaProductos: 200 });
      return _.sumBy(productos, (p) => Number(p.cantidad));
    },
    totalProductos() {
        return _.sumBy(this.productosLotes, p => Number(p.cantidad));
      },
  },
  mounted() {
    this.cargarDatosIniciales();
  },
  methods: {
    isEmpty: _.isEmpty,
    clone:_.clone,
    cargarDatosIniciales() {
      if (this.ventaId != null) {
        this.obtenerVenta(this.ventaId);
      }
      this.cargarDatosSesion();
    },
    cargarDatosSesion(){
      this.tienda = JSON.parse(localStorage.getItem('tienda'))
    },
    async obtenerVenta(ventaId) {
      this.limpiarALL();
      this.loader(true);
      this.ventaId = ventaId;
      await this.obtenerVentaDetalle();
      this.buscarVentasModal = false;
      this.loader(false);
    },
    obtenerVentaDetalle(loader = true) {
      let filtros = {
        ventaId: this.ventaId,
      };
      if (loader) this.loader(true);
      return inventarioServices
        .obtenerDetalleVenta(filtros)
        .then((resp) => {
          this.ventaObj = resp.venta;
          this.productos = _.orderBy(_.filter(resp.detalle, d => Number(d.controlInventario) > 100), "entregaProductos", "asc");
        })
        .catch((error) => {
          this.alertShow(error, "error");
        })
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    cargarConfiguracionProducto(producto, index) {
      if (producto.entregaProductos !== 100) return;
      this.lstLotesSeries = [];
      this.productosLotes = [];
      this.productoObj = this.clone(producto);
      this.indexSeleccionado = index;
      this.$nextTick(() => this.$refs.busqueda.focus());
    },
    buscarLotesSeries(loader = true) {
      let filtros = {
        busqueda: this.filtrosLotes.busqueda,
        productosIds: `'${this.productoObj.productoId}'`,
        /*tiendasIds: `'${this.tienda.tiendaId}'`,*/
        almacenesIds: `'${this.ventaObj.almacenId}'`,
      };
      if (loader) this.loader(true);
      return inventarioServices.listarLotesSeries(filtros).then((resp) => {
        let array = _.differenceWith(resp, this.productosLotes, (ls, pl) =>
          ls.productoLoteSerieId === pl.productoLoteSerieId && ls.cantidadRestante === pl.cantidad);

        this.lstLotesSeries = array;
        if (array.length === 1)
          return this.obtenerLoteSerie(this.lstLotesSeries[0]);

        this.modalSalidaProducto = true;
      }).catch(error => this.alertShow(error, "error"))
        .then(() => {if (loader) this.loader(false);});
    },
    seleccionarLoteSerieRow(evt, row, index) {
        this.obtenerLoteSerie(row);
      },
    seleccionarLoteSerie(details) {
      this.obtenerLoteSerie(details.rows[0]);
    },
    obtenerLoteSerie(row) {
      this.loteSerieObj = _.clone(row);
      this.agregarLoteSerie(false);
    },
    agregarLoteSerie(configCantidad = false) {
        let loteSerie = this.loteSerieObj,
            indexLoteSerie = _.findIndex(this.productosLotes, {productoLoteSerieId: loteSerie.productoLoteSerieId}),
            cantidadAsignada = indexLoteSerie !== -1 ? this.productosLotes[indexLoteSerie].cantidad : 0,
            cantidadPorAsignar = Number(this.productoObj.cantidadActual) - Number(this.totalProductos);

        if (configCantidad && Number(this.productoObj.controlInventario) > 200) {
          this.cantidadSalidaLotesSeries = cantidadAsignada;
          return this.modalConfigurarCantidades = true;
        }

        cantidadAsignada = Number(this.productoObj.controlInventario) > 200 ? (this.modalConfigurarCantidades ? Number(this.cantidadSalidaLotesSeries) : 1) : 1;
        let loteSerieCantidad = indexLoteSerie !== -1 ? this.productosLotes[indexLoteSerie].cantidad : 0,
            sumaLotesRegistrados = _.isEmpty(this.productosLotes) ? 0 : _.sumBy(this.productosLotes, pl => Number(pl.cantidad)) - loteSerieCantidad,
            cantidadActual = Number(this.productoObj.cantidadActual);

        if ((Number(sumaLotesRegistrados) + cantidadAsignada) > cantidadActual || Number(cantidadAsignada) <= 0)
          return  this.alertShow("Cantidad invalida", "error");

        if (indexLoteSerie !== -1 ) {
          this.productosLotes[indexLoteSerie].cantidad = this.modalConfigurarCantidades ? cantidadAsignada : loteSerieCantidad + 1;
        } else {
          this.productosLotes.push({
            id: this.productosLotes.length + 1,
            cantidad: cantidadAsignada,
            productoId: loteSerie.productoId,
            claveInterna: this.productoObj.claveInterna,
            nombre: this.productoObj.descripcion,
            controlInventario: this.productoObj.controlInventario,
            productoPedimento: this.productoObj.productoPedimento,
            lote: Number(this.productoObj.controlInventario) > 200 ? loteSerie.loteSerie : "",
            caducidad: loteSerie.caducidad,
            numeroPedimento: loteSerie.pedimento,
            pedimentoId: loteSerie.pedimentoId,
            numeroSerie: Number(this.productoObj.controlInventario) === 200 ? loteSerie.loteSerie : "",
            productoLoteSerieId: loteSerie.productoLoteSerieId,
            ventaDetalleId: this.productoObj.ventaDetalleId,
            cantidadRestante: loteSerie.cantidadRestante
          });
        }

        this.filtrosLotes.busqueda = "";
        this.lstLotesSeries = [];
        this.modalConfigurarCantidades = false;
        this.modalSalidaProducto = false;
        this.$refs.busqueda.focus();
      },
    editarCantidad(producto) {
      this.loteSerieObj = _.clone(producto);
      this.agregarLoteSerie(true);
    },
      buildColumnasProductosLotes(){
        let columnas = [];

        if(this.productoObj && this.productoObj.controlInventario > CONTROL_INVENTARIO_NUMERO_SERIE)
          columnas.push({name: "lote",label: "Lote",field:"lote",align: "left",headerClasses: "w25p",},);

          if(this.productoObj.controlInventario === CONTROL_INVENTARIO_NUMERO_SERIE)
          columnas.push({name: "numeroSerie",label: "Número serie",field:"numeroSerie",align: "left",headerClasses: "w25p",},);

        if(this.productoObj && this.productoObj.controlInventario === CONTROL_INVENTARIO_LOTES_CADUCIDADES)
          columnas.push({name: "caducidad",label: "Caducidad",field:"caducidad",align: "left",headerClasses: "w10p", format: val => this.dateFormat(val, 2, false)},);

        if(this.productoObj && this.productoObj.productoPedimento > 100)
          columnas.push({name: "pedimento",label: "Pedimento",field:"numeroPedimento",align: "left",headerClasses: "w20p", format: val => val ? val : '--'},);

        columnas.push({name: "cantidad",label: "Cantidad",field:"cantidad",align: "center",headerClasses: "w8p",});

        return columnas;
      },
      eliminarProducto(index) {
        let productos = _.cloneDeep(this.productosLotes);
        this.productosLotes = [];
        productos.splice(index, 1);
        this.productosLotes = productos;
        this.$nextTick(() => this.$refs.busqueda.focus());
      },
      validarFinalizarEntrega() {
        let productoCantidad = Number(this.productoObj.cantidadActual);
        if (this.totalProductos < productoCantidad)
          return this.alertShow("Faltan lotes, números de serie y/o caducidades por agregar.", "error");

        this.modalConfirmacion = true;
      },
      guardarEntrega(){
        this.loader(true);
        let data = {
          ventaId :this.ventaObj.ventaId,
          tipo:1,
          productosStr:JSON.stringify(this.productosLotes),
        };

        return inventarioServices.salidaLotesSeries(data, false)
          .then(() => {
            this.alertShow("Se registro salida de lotes y series correctamente", "exito");
            this.limpiarALL();
            this.obtenerVentaDetalle();
            this.loader(false);
          }).catch(error => {
            this.loader(false);
            this.alertShow(error, "error");
          });
      },
      limpiarALL() {
        this.modalSalidaProducto = false;
        this.modalConfirmacion = false;
        this.productoObj = {};
        this.productos = [];
        this.productosLotes = [];
        this.lstLotesSeries = [];
        this.loteSerieObj = "";
      },
  },
};
