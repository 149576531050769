import RequestAxios         from "../Repositories/RequestAxios";
import axiosUtils           from "./Requests/AxiosUtils";
import VentaServices from './VentaServices';
const ventaServices = new VentaServices();

export default class InventarioServices{
  async listarVentas(filtros) {
    return ventaServices.listarVentas(filtros);
  }
  async obtenerDetalleVenta(filtros) {
    return ventaServices.obtenerDetalleVenta(filtros);
  }
  async listarLotesSeries(filtros) {
    let url       = "productosLotesSeries/listarLotesSeriesMostrador",
    timestamp     = new Date().getTime(),
    hash          = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

  let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
  return RequestAxios.requestGET(url, data);
  }

  async salidaLotesSeries(params, blockAutorId) {
    let url       = "productosLotesSeries/salidaLotesSeriesMostrador",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data, blockAutorId);
  }
}